import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "components/Layout/Layout"
import MoreTopics from "../../../components/Education/MoreTopics"
import SnowQueen from "../../../components/Education/TheSnowQueen/SnowQueen";
import styled from "styled-components";
import SnowFlakes from "../../../components/shared/SnowFlakes";

const pageSlug = "the-snow-queen"
const pageTitle = "The Snow Queen"
const categorySlug = "inspiration"
const categoryTitle = "Inspiration"

const Header = styled.div`
    position: relative;
`

const Content = styled.div`
    position: relative;
    text-align: center;
`

class TheSnowQueen extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: pageSlug,
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {

        return (
            <Layout categorySlug={categorySlug}
                    category={categoryTitle}
                    currentPage={pageTitle}
                    pageName={pageSlug}
                    mainCategorySlug="beyond-the-story"
                    enrichmentHeader>
                <SEO slug={pageSlug} title={pageTitle} description=""/>
                <SnowFlakes>
                    <Content className="enrichment">
                        <SnowQueen/>
                        <div className="py-5">&nbsp;</div>
                        <MoreTopics adaptation design/>
                    </Content>
                </SnowFlakes>
            </Layout>
        )
    }
}

export default TheSnowQueen