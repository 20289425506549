import React from 'react'
import styled from "styled-components"
import {graphql, StaticQuery} from "gatsby"
import {Row, Col, Container} from 'reactstrap'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import HeadingSpecial from "../../shared/HeadingSpecial";

const SnowQueen = styled.div`
    position: relative;
    text-align: center;
    
    .narrow {
        max-width: 740px;
        margin: 0 auto;
    }
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                snowQueenImg: file(relativePath: { eq: "TheSnowQueen/insp-snowqueen-pic1-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1055, quality: 100)
                    }
                }
                horsesMd: file(relativePath: { eq: "TheSnowQueen/insp-snowqueen-pic2-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                horses: file(relativePath: { eq: "TheSnowQueen/insp-snowqueen-pic2-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 740, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <SnowQueen>
                <Container fluid={true} className="no-padding-xs">
                    <HeadingSpecial lines={false} headingTag="h1"
                                    className="py-4 py-md-5 font--bigger"
                                    title="The Snow Queen"/>
                    <Row className="mb-4 mb-xl-5">
                        <Col xs={12} xl={6} className="my-auto">
                            <div className="narrow">
                                <p className="font--regular mb-4 font-weight-bold">FROZEN is an original story, loosely based on Hans Christian Andersen’s “The Snow Queen.”</p>

                                <p className="font--regular mb-4">Published in 1845, “The Snow Queen” begins when a magic mirror created by trolls shatters, causing pieces of it to lodge in the heart and eye of a boy named Kai. The mirror shards change Kai, and he leaves his home to go and live with the Snow Queen. His playmate, Gerda, goes in search of Kai and after many adventures she finds him. Her kisses melt the mirror-shards and free him from the queen.</p>
                            </div>

                        </Col>
                        <Col xs={12} xl={6} className="my-auto">
                            <div className="narrow">
                            <GatsbyImage placeholder="none" image={getImage(data.snowQueenImg)} alt="" title=""
                                 className="img-fluid"/>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 mb-xl-5">
                        <Col xs={12} xl={6} className="my-auto order-2 order-xl-1">
                            <div className="narrow">
                            <GatsbyImage placeholder="none" image={getImage(data.horsesMd)} alt="" title=""
                                 className="img-fluid d-lg-none"/>
                            <GatsbyImage placeholder="none" image={getImage(data.horses)} alt="" title=""
                                 className="img-fluid d-none d-lg-block"/>
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="my-auto order-1 order-xl-2 pb-4 pb-xl-0">
                            <div className="narrow">
                                <p className="font--regular mb-4">Since its publication, “The Snow Queen” has been adapted into numerous films, plays, operas and ballets. Disney’s journey with the story began in the 1940s, when many of Andersen’s fairy tales were being explored for animation. The project was ultimately shelved, though exploration continued in the 1970s for a Disneyland attraction called “The Enchanted Snow Palace”, which didn’t come to fruition.</p>
                                <p className="font--regular mb-4">“The Snow Queen” began its transformation into FROZEN in the early 2000s. While the final film departs from the original story, the theme of “regeneration through faith,” its lead character’s abilities, and a journey through mountains for reunification remain. The filmmakers also gave a nod to Gerda in the character of Anna and to the faithful reindeer who comes to Gerda’s aid in the character of Sven.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SnowQueen>
        )}
    />
)

export default Query;